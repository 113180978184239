/* LoginPage.css */
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #333; /* Background color (black) */
  color: #fff; /* Text color (white) */
}

.login-box {
  text-align: center;
  max-width: 400px;
  padding: 20px;
  background-color: #fff; /* Box background color (white) */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow */
}

.company-logo {
  width: 100px; /* Adjust the width of the company logo */
  height: auto;
  margin-bottom: 10px;
}

.company-name {
  font-size: 16px;
  font-weight: bold;
  color: #333; /* Company name color (black) */
  margin-bottom: 20px;
}

.login-label {
  display: block;
  margin-bottom: 10px;
  text-align: left; 
  color: #333; /* Label color (black) */
}

.login-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  text-align: right; 
}

.login-button {
  background-color: #333; /* Button background color (black) */
  color: #fff; /* Button text color (white) */
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #555; /* Button background color on hover */
}
