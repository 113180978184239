.image-container {
  display: flex;
  align-items: center;
}

.image-container.swap {
  flex-direction: row-reverse;
}

.image img {
  max-width: 60%;
  height: auto;
}

.image-content {
  max-width: 60%;
}
