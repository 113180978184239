table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px; /* Optional margin for better spacing */
}

th,
td {
  border: 1px solid #ddd; /* Optional border for cells */
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2; /* Optional background color for header cells */
}
