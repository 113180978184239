.PolicyPage {
  margin: 0 auto;
  margin-top: 100px;
  width: 95%;
}

.PolicyPage h1 {
  font-size: 1.5rem;
}

.PolicyPage h2 {
  font-size: 1.25rem;
}

.PolicyPage p {
  font-size: 1rem;
}
