.App {
  text-align: center;
  /* background-image: url('/public/background.jpg'); */
  background-size: cover;
  background-position: center;
  width: 95%;
  margin: 0 auto;
}

.App-header {
  /* background-color: rgba(0, 0, 0, 0.5); Add a semi-transparent overlay */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative; /* Make the header a positioned container */
}

.App-logo {
  position: absolute; /* Position the logo absolutely within the header */
  top: 20px; /* Distance from the top of the header */
  left: 20px; /* Distance from the left of the header */
  height: 80px; /* Adjust the height of the logo */
  animation: none; /* Disable any animations */
  transform: none; /* Reset any transformations */
}

.App-header h1 {
  font-size: 3em;
}

.App-header p {
  font-size: 1.5em;
}

.title {
  margin-top: 6px;
  padding: 10px;
  font-size: 1.25rem;
  color: black;
}
.clickImage img {
  max-width: 100% !important;
}
